export namespace AppTitleGenerator {

	/** Base title. */
	export const baseTitle = 'MEI - Customer Storage';

	/**
	 * Generate title.
	 * @param titles Titles to generate.
	 */
	export function generate(titles: string[]): string {
		const concattedTitle = titles.join(' - ');
		return `${baseTitle} | ${concattedTitle}`;
	}
}
